import React                  from 'react'
import Img                    from "gatsby-image"
// import cx                     from 'utils/classnames'
// import {
//   Col,
// }                             from 'react-bootstrap'
import styles                 from './styles.module.scss'


const SquareGrid = ({ children }) => (
  <div className={styles.root}>
    <div className={styles.scrollArea}>
      {children}
    </div>
  </div>
)


SquareGrid.Item = ({ image, children }) => (
  <div className={styles.item}>
    <Img className={styles.itemImg} fluid={{ ...image, aspectRatio: 1 }} />
    <div className={styles.itemBody}>
      {children}
    </div>
  </div>
)

export default SquareGrid

import React                  from 'react'
import { FaSearch }           from 'react-icons/fa'
import Icon                   from 'entrada-ui/Icon'
import Autosuggest            from 'entrada-ui/Autosuggest'
import styles                 from './styles.module.scss'

const renderInputComponent = inputProps => (
    <div>
        <Icon className={styles.searchIcon}>
            <FaSearch />
        </Icon>
        <input className={styles.input} {...inputProps} />
    </div>
)


const TourSearch = ({
    tours,
    themes,
    destinations
}) => (
    <div className={styles.search}>
        <Autosuggest
            renderInputComponent={renderInputComponent}
            placeholder='Search for "Hobbiton" or "Queenstown"'
            suggestions={[{
                title: 'Tours',
                entries: tours
            }, {
                title: 'Themes',
                entries: themes
            }, {
                title: 'Destinations',
                entries: destinations
            }]}
        />
    </div>
)

export default TourSearch

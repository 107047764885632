import React, { useState }                from 'react'
import { map }              from 'lodash-es'
import cx                   from 'utils/classnames'
import {
    FaClock,
}                           from 'react-icons/fa'
import {
    Container,
}                           from 'react-bootstrap'
import {
    useWindowSize,
    StringToIcon
}                           from 'utils'
import Icon                 from 'entrada-ui/Icon'
import Typography           from 'entrada-ui/Typography'
import Banner               from 'components/Banner'
import Link                 from 'components/Link'
import Seo                  from 'components/Seo'
import Slideshow            from 'components/Slideshow'
import SquareGrid           from 'components/SquareGrid'
import TourCards            from 'components/TourCards'
import TourSearch           from 'components/TourSearch'
import freeCancellation     from 'images/home-free_cancellation.svg'
import styles               from './styles.module.scss'

const Feature = ({ children }) => <div className={styles.feature}>{children}</div>
const FeatureIcon = ({ children }) => <Icon className={styles.featureIcon}>{children}</Icon>
const TourCard = TourCards.CardWp

export default (props) => {
    const {
        pageContext: {
            data: {
                siteMetadata,
                hero_image,
                acf: {
                    page_heading,
                    gallery_slider,
                    icon_group,
                    top_tours,
                    destinations
                },
                search: {
                    toursList,
                    themesList,
                    destinationsList
                },
                yoast_meta
            }
        }
    } = props

    const wSize = useWindowSize()

    return (
        <>
            <Seo meta={yoast_meta} siteMetadata={siteMetadata} />
            <div className={styles.hero}>
                <Slideshow
                    className={styles.slideshow}
                    noTransition={wSize.isMobile}
                >
                {gallery_slider.map((slide, idx) => (
                    <Slideshow.Slide key={idx}>
                        <Slideshow.Img
                            key={idx}
                            className={styles.slideImage}
                            fluid={
                                wSize.isMobile ?
                                hero_image :
                                slide.acf.gallery[0].localFile.childImageSharp.fluid
                            }

                        />
                        <div className={styles.slideDescription}>
                            This photo is from our
                            <Link className={styles.slideDescriptionLink} to={slide.url}>
                                {slide.acf.tour_name}
                            </Link>
                        </div>
                    </Slideshow.Slide>
                ))}
                </Slideshow>

                <div className={styles.heroSearchBox}>
                    <h1 className={styles.heroTitle}>{page_heading}</h1>
                    <TourSearch tours={toursList} themes={themesList} destinations={destinationsList} />
                </div>
            </div>


            <div className={styles.features}>
                <Container>
                    <div className={styles.featuresList}>
                        {icon_group.map((item, idx) => (
                        <Feature key={idx}>
                            <FeatureIcon>
                                <StringToIcon
                                    icon={item.icon}
                                />
                            </FeatureIcon>
                            <p>{item.icon_title}</p>
                        </Feature>
                        ))}
                    </div>
                </Container>
            </div>

            <Container>
                <Typography className={styles.topTours} variant="h1" component="h2">
                    Top New Zealand Tours
                </Typography>

                <TourCards>
                {map(top_tours, (tour, idx) => (
                    <TourCard
                        key={idx}
                        {...tour}
                    />
                ))}
                </TourCards>
            </Container>

            <Banner className='mt-5 mb-5'>
                {/* <Banner.Icon fontSize='large'>
                    <FaClock />
                </Banner.Icon> */}
                <img src={freeCancellation} alt="Free Cancellation"/>
                <Banner.Title>Free cancellation</Banner.Title>

                <Banner.Text>
                    <p>You'll receive a full refund if you cancel at least 2 hours in advance of your tour experience.</p>
                </Banner.Text>
            </Banner>

            <Container>
                <Typography variant="h1" component="h2">
                    Destinations
                </Typography>

                <SquareGrid>
                    {destinations.map((destination, idx) => (
                    <SquareGrid.Item key={idx} image={destination.acf.card_image.localFile.childImageSharp.fluid}>
                        <Link className={styles.gridItemTitle} to={destination.url}>{destination.acf.card_title}</Link>
                    </SquareGrid.Item>
                    ))}
                </SquareGrid>
            </Container>

            <Banner className={cx(styles.lastBanner, 'mt-5')}>
                <Banner.Title>TOUR COMBO DEALS</Banner.Title>

                <Banner.Text>
                    <p>Get more out of your New Zealand holiday with a great value GreatSights sightseeing tour deal. Book more than one tour and save!</p>
                </Banner.Text>

                <Banner.Button to="/find-a-new-zealand-tour/tour-combos">SEE ALL COMBOS</Banner.Button>
            </Banner>
        </>
    )
}

import React                from 'react'
import ReactAutosuggest     from 'react-autosuggest'
import RawHtml              from 'entrada-ui/RawHtml'
import TextField            from 'entrada-ui/TextField'
import styles               from './styles.module.scss'


// TODO Move this to the page component
import { navigate } from "gatsby"

// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
const escapeRegexCharacters = (str) => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

const getSuggestions = (value, suggestions) => {
    const escapedValue = escapeRegexCharacters(value.trim()).toLowerCase()

    if (escapedValue === '') {
      return []
    }


    return suggestions
      .map(section => ({
        title: section.title,
        entries: section.entries.filter(entry => entry.name.toLowerCase().includes(escapedValue))
      }))
      .filter(section => section.entries.length > 0)
  }

const getSuggestionValue = (suggestion) => suggestion.name

const renderSuggestion = (suggestion) => (
    <span>{suggestion.name}</span>
)

const renderSectionTitle = (section) => (
    <strong>{section.title}</strong>
)

const getSectionSuggestions = (section) => section.entries

const onSuggestionSelected = (event, { suggestion }) => {
    navigate(suggestion.url)
}

const renderInputComponent = inputProps => <TextField InputProps={inputProps} />

const Autosuggest = React.memo(React.forwardRef(function Autosuggest(props, ref) {
    const {
        // (static search) If this is null, then suggestionsUrl must be provived
        suggestions: suggestionsProp = [],
        // (dynamic search) If this is null, then suggestions must be provided
        // suggestionsUrl = null,
        // multiSection = false,
        renderInputComponent: renderInputComponentProp = renderInputComponent,
        placeholder,
        onSuggestionSelected: onSuggestionSelectedProp = onSuggestionSelected
    } = props

    const [suggestions, setSuggestions] = React.useState([])
    const [inputValue, setInputValue] = React.useState('')

    // TODO Use ajax to get the suggestions
    const onSuggestionsFetchRequested = ({ value }) => setSuggestions(getSuggestions(value, suggestionsProp))

    const onSuggestionsClearRequested = () => setSuggestions([])

    const onChange = (event, { newValue, method }) => setInputValue(newValue)

    // TODO Remove this
    const inputProps = {
        placeholder,
        value: inputValue,
        onChange: onChange
    }

    return (
        <ReactAutosuggest
            theme={styles}
            multiSection={true}
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            onSuggestionSelected={onSuggestionSelectedProp}
            renderInputComponent={renderInputComponentProp}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            renderSectionTitle={renderSectionTitle}
            getSectionSuggestions={getSectionSuggestions}
            inputProps={inputProps}
        />
    )
}))

export default Autosuggest
